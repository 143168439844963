body {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    padding: 20px;
}

div {
    margin-bottom: 20px;
}

input[type="number"] {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    margin-right: 10px;
}

button {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #4CAF50;
    color: white;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #ddd;
}

input[type="checkbox"] {
    cursor: pointer;
}

.share-amount {
    font-weight: bold;
    color: #ff5722;
}
